import { configureStore, createSlice } from "@reduxjs/toolkit";

// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Error loading state:", err);
    return undefined;
  }
};

// Save state to localStorage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Error saving state:", err);
  }
};

const initialState = loadState() || {
  products: [],
  categories: [],
  lastFetched: null,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
      state.lastFetched = Date.now();
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    clearData(state) {
      state.products = [];
      state.categories = [];
      state.lastFetched = null;
    },
  },
});

export const { setProducts, setCategories, clearData } = productsSlice.actions;

const store = configureStore({
  reducer: {
    products: productsSlice.reducer,
  },
});

// Subscribe to store changes and save to localStorage
store.subscribe(() => {
  saveState(store.getState());
});

// Utility function to check if data needs to be refetched
export const shouldFetchData = (state, maxAge = 3600000) => {
  if (!state.products.lastFetched) return true;
  const now = Date.now();
  return now - state.products.lastFetched > maxAge;
};

export default store;
